import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./../MainHeader.module.scss";
import SlidingWidget, {CaptionButton, SlidingWidgetFooter} from "./SlidingWidget"
import { SearchWidget, TreeList } from "@netapp/bxp-design-system-react"
import serviceConnectorMenuStyles from "./ConnectorMenu.module.scss";
import _ from "lodash";
import { FolderIcon, CompanyIcon, ProjectIcon, DropdownArrowDownIcon, DropdownArrowUpIcon  } from "@netapp/bxp-design-system-react/icons/monochrome";
import { useLocation } from 'react-router-dom';
import { recursiveSearch } from "modules/identity-access-management/utils";

const ScopeMenuFooter = ({activeScope, selectedScope}) => {
    const dispatch = useDispatch();

    return <SlidingWidgetFooter onSwitch={() => {
        if(activeScope === selectedScope){
            return;
        }

        dispatch({
            type: "TENANCYV4:SET-SCOPE",
            payload: {
                scopeId: selectedScope.id
            },
            track: true
        });

        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }} onClose={() => {
        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }}/>
};



const MenuContent = React.memo(({setSelectedScope}) => {
    const activeScope = useSelector(state => state.tenancy.selectedScope);
    const orgs = [useSelector(state => state.tenancy.selectedOrg)] || [];
    const [activeFilter, setActiveFilter] = useState("");

    const filteredScopes = useMemo(() => {
        if(activeFilter) {
            let selectedOgranizationData = _.cloneDeep(orgs);
            const filteredData= selectedOgranizationData[0].childScopes?.length ?
                recursiveSearch("childScopes", [selectedOgranizationData[0]], activeFilter, orgs.id) : [];
            return filteredData.length ? filteredData : [];
        } else {
            return orgs;
        }
    }, [activeFilter, orgs]);

    const expandAll = useMemo(() => {
        if(activeFilter) {
            return 'max'
        } else {
            return 1;
        }
    }, [activeFilter, orgs]);

    const onChangeEvent = (scope) => {
        setSelectedScope(scope[0]);
    }

    const disableElement = (ele) => {
        return _.toLower(ele.resourceType) === "organization" || _.toLower(ele.resourceType) === "folder"
    }

    const iconRenderer = (element) => {
        if (element.resourceType === "organization") {
            return <CompanyIcon className={element?.isSelected ? "" : styles["tenancy-icon-color"]} width="20"/>
        } else if (element.resourceType === "folder") {
            return <FolderIcon className={element?.isSelected ? "" : styles["tenancy-icon-color"]} width="20"/>
        } else if (element.resourceType === "project") {
            return <ProjectIcon className={element?.isSelected ? "" : styles["tenancy-icon-color"]} width="20"/>
        }
        return null
    }

    const customStyles = {
        treeItemBase: {
            height: '55px'
        },
        treeListBase: {
            maxHeight: 'unset'
        }
    }

    return <div style={{display: "inline"}}>
        <SearchWidget color="secondary" className={serviceConnectorMenuStyles['search-widget-container']} alwaysOpen={true} placeholder="Search" setFilter={setActiveFilter} widgetClassName={serviceConnectorMenuStyles['search-widget']}/>
        <div className={styles["dropdown-icon"]} style={{margin: "15px 40px 0px 40px", overflowY: 'auto', height: '85%'}}>
            <TreeList
                treeData={filteredScopes}
                menuType={'singleRadio'}
                uniqueIdentifier={'id'}
                labelKey={'name'}
                bottomBorder={true}
                childKey={'childScopes'}
                selectedValues={[activeScope]}
                onChange={onChangeEvent}
                expandLevel={expandAll}
                isOptionDisabled={disableElement}
                iconRenderer={iconRenderer}
                customStyles={customStyles}
                dropdownUpIcon={() => <DropdownArrowDownIcon
                    className={styles["tenancy-icon-color"]}/>} 
                dropdownDownIcon={() => <DropdownArrowUpIcon
                    className={styles["tenancy-icon-color"]}/>}
                    />
        </div>
    </div>
});

export default () => {
    const [isDisabled, setIsDisabled] = useState(false);
    const location = useLocation();
    const activeScope = useSelector(state => isDisabled ? state.tenancy.selectedOrg : state.tenancy.selectedScope);
    const selectedOrg = useSelector(state => state.tenancy.selectedOrg)
    const scope = useSelector(state => state.tenancy.selectedScope)
    const [selectedScope, setSelectedScope] = useState(activeScope);
    const scopeName = activeScope?.name || "N/A";
    const dispatch = useDispatch();

    useEffect(() => {
        setIsDisabled(location.pathname.includes("/identity-access-mgmt"))
        if (!_.isUndefined(scope)) {
            let scopeId = scope.id;
            if (location.pathname.includes("/identity-access-mgmt")) {
                scopeId = selectedOrg.id
            }
            dispatch({
                type: "TENANCYV4:SET-PERMISSIONS",
                payload: {
                    scopeId
                }
            })
        }
        
    }, [location, selectedOrg, scope]);

    return <SlidingWidget
        widgetKey="scope"
        menuTitle="Projects"
        triggerDisabled={isDisabled}
        canClose={() => {
            return false;
        }}
        triggerClassName={styles['caption-button']}
        triggerActiveClassName={[styles['caption-button-active']]}
        TriggerButton={(props) => <CaptionButton isDisabled={isDisabled} title="Project" value={scopeName} {...props}/>}
        MenuFooter={() => <ScopeMenuFooter activeScope={activeScope} selectedScope={selectedScope}/>}
    >
        <MenuContent selectedScope={selectedScope} setSelectedScope={setSelectedScope}/>
    </SlidingWidget>
};
