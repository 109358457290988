import React, { useState, useEffect } from "react";
import styles from "./MoveToSaas.module.scss"
import {ReactComponent as House} from "./house.svg";
import { ReactComponent as X } from "./x.svg";
import { BulletList, Button, ExternalLink } from "@netapp/bxp-design-system-react";
import externals from "../../../../utils/externals";
import {useSelector} from "react-redux";

const Content = ({setMoveToSaasClosed}) => {
    const localAccountId = useSelector(state => state.tenancy.localAccountId);
    const localConnectorId = useSelector(state => state.tenancy.localConnectorId);
    const [fadeIn, setFadeIn] = useState();

    const continueWithLocal = () => {
        window.localStorage.setItem("occm.dont.show.move.to.saas.overlay", "true");
        setMoveToSaasClosed(true)
    };

    const connectorIdSuffix = localConnectorId ? `&connectorId=${localConnectorId}` : "";
    const url = `${externals.saasFrontend}?accountId=${localAccountId}${connectorIdSuffix}`;

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return <div className={styles['common-screen']} style={{opacity: fadeIn ? 1 : 0}}>
        <div className={styles['common-header']}>
            <div className={styles['sub-header']}>
                <span className={styles['move-saas-title']}>BlueXP has a new home</span>
                <div style={{marginTop: 18}}>
                    <a className={styles['move-saas-sub-title']} href={url}>console.bluexp.netapp.com</a>
                </div>
            </div>
            <X onClick={continueWithLocal} className={styles['close-icon']}/>
        </div>
        <div className={styles['move-to-saas']}>
            <div>
                <div className={styles['benefit-title']}>The benefits for you include:</div>
                <BulletList liStyle={{fontSize: 16}} style={{marginTop: 33}}>
                    <>A new SaaS experience</>
                    <>Additional management capabilities</>
                    <>The ability to easily switch between your Cloud Central accounts</>
                    <>No longer needing to access a user interface from software that runs in your network</>
                </BulletList>
                <div className={styles['learn-instructions']}>
                    <ExternalLink newTab={false} className={styles['continue-to-saas-button']}
                          large
                          href={url}>Lets go to BlueXP</ExternalLink>
                    <ExternalLink href="https://docs.netapp.com/us-en/occm/concept_saas.html" className={styles['learn-more-btn']} color={"secondary"} newTab>Learn more</ExternalLink>
                </div>
                <Button variant="text" className={styles['keep-using-button']} onClick={continueWithLocal}>Keep using the local UI available from the connector (not recommended)</Button>
            </div>
            <House className={styles['move-to-saas-icon']}/>
        </div>
    </div>
}

export default React.memo(() => {
    const [isMoveToSaasClosed, setMoveToSaasClosed] = useState(false);

    if(!isMoveToSaasClosed) {
        return <>
            <div className={styles.mask}/>
            <Content setMoveToSaasClosed={setMoveToSaasClosed}/>
        </>
    }
    return ""
});
