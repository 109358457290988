import { Button, DialogLazy, MainLoader, Modal, ModalContent, ModalFooter, ModalHeader, Notification, removeMainLoader } from "@netapp/bxp-design-system-react";
import classNames from "classnames";
import _ from "lodash";
import AppNotifications from "modules/app/components/AppNotifications";
import { MonitorCurrentConnector } from "modules/current-connector";
import React, { Suspense, useEffect, useRef } from 'react';
import { lazy } from "react-imported-component/macro";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from "store";
import { getItemAnon, isNewTenancyEnabled, setItemAnon } from "utils/localstorage";
import styles from "./app.module.scss";
import NgWrapper from "./modules/app/NgWrapper";
import ServiceIntegration from "./modules/app/ServiceIntegration";
import DefaultRoute from "./modules/app/components/DefaultRoute";
import GTCDataCollectionPage from "./modules/app/components/GTCDataCollectionPage";
import InactiveConnectorDialog from "./modules/app/components/InactiveConnectorDialog";
import Intercom from "./modules/app/components/Intercom";
import MainErrorBoundary from "./modules/app/components/MainErrorBoundary";
import MainHeader from "./modules/app/components/MainHeader";
import MainNav from "./modules/app/components/MainNav";
import { Forbidden } from "./modules/app/components/NoAccessComponents";
import NoAccountDialog from "./modules/app/components/NoAccountDialog";
import NoOrgDialog from "./modules/app/components/NoOrgDialog";
import SlidingManagementWidget from './modules/app/components/SlidingManagementWidget';
import MoveToSaas from './modules/app/components/moveToSaas/MoveToSaas';
import NavMovedDialog from './modules/app/components/navMoved/NavMoved';
import WhatsNew from './modules/app/components/whatsNew/WhatsNew';
import { whatsNewConfig } from './modules/app/components/whatsNew/whatsConfig';

const Playground = lazy(() => import('./modules/playground'/* webpackChunkName: "Playground" */));

const GalleryTab = lazy(() => import('./modules/gallery'/* webpackChunkName: "Gallery" */).then(r => r.GalleryTab));

export const ProxyDialog = process.env.REACT_APP_IS_DARK !== "true" ?
    lazy(() => import("./modules/local" /* webpackChunkName: "Local" */).then(r => r.ProxyDialog)) :
    lazy(() => import("./modules/dark" /* webpackChunkName: "Dark" */).then(r => r.ProxyDialog));

export const SetupDialog = process.env.REACT_APP_IS_DARK !== "true" ?
    lazy(() => import("./modules/local" /* webpackChunkName: "Local" */).then(r => r.SetupDialog)) :
    lazy(() => import("./modules/dark" /* webpackChunkName: "Dark" */).then(r => r.SetupDialog));

export const DarkLogin = process.env.REACT_APP_IS_DARK === 'true' ?
    lazy(() => import("./modules/dark" /* webpackChunkName: "Dark" */).then(r => r.Login)) : '';

const SpotTab = lazy(() => import('./modules/spot' /* webpackChunkName: "spot"*/));

const CVS = lazy(() => import('./modules/cvs' /* webpackChunkName: "cvs"*/));

const NetappInternal = lazy(() => import('./modules/netapp-internal' /* webpackChunkName: "Internal"*/));

const Support = lazy(() => import('./modules/support' /* webpackChunkName: "Support"*/));

const Licenses = lazy(() => import('./modules/licenses' /* webpackChunkName: "Licenses"*/));

const StorageResources = lazy(() => import('./modules/storage' /* webpackChunkName: "StorageResources"*/));

const HttpsSetup = lazy(() => import('modules/connector-settings/index' /* webpackChunkName: "HTTPS"*/).then(r => r.HttpsSetup));

// Lazy load Identity and Access Management module.
const IdentityAndAccessMgmt = lazy(() => import('modules/identity-access-management/index' /* webpackChunkName: "Tenancy"*/).then(r => r.Settings));

const Timeline = lazy(() => import('./modules/timeline' /* webpackChunkName: "Timeline"*/));

const AddNewWorkingEnvironment = lazy(() => import('./modules/add-new-working-environment' /* webpackChunkName: "AddNewWorkingEnvironment"*/));

const ReplicationTab = lazy(() => import('./modules/replication' /* webpackChunkName: "Replication"*/).then(r => r.ReplicationTab));

const ReplicationSetupWizard = lazy(() => import('./modules/replication/wizard' /* webpackChunkName: "ReplicationWizard"*/).then(r => r.ReplicationSetupWizard));

const Settings = lazy(() => import('modules/connector-settings/index' /* webpackChunkName: "Settings"*/).then(r => r.Settings));

const Credentials = lazy(() => import('modules/credentials' /* webpackChunkName: "Credentials"*/));

const AlertsAndNotifications = lazy(() => import('modules/alerts-and-notifications' /* webpackChunkName: "AlertsAndNotifications"*/))

const RedirectManager = lazy(() => import('modules/app-redirect' /* webpackChunkName: "AppRedirect"*/));

const Alerts = lazy(() => import('./modules/bxp-alerts' /* webpackChunkName: "bxp-alerts"*/).then(r => r.Alerts));

const AlertDetails = lazy(() => import('./modules/bxp-alerts' /* webpackChunkName: "bxp-alerts"*/).then(r => r.AlertDetails));

const routesAvailableWithoutAnActiveConnector = ["/working-environments", "/timeline", "/replication", "/sync", "/add-working-environment-wizard", "/cloud-volumes-service", "/credentials"];

const TimeoutModal = () => {
    const dispatch = useDispatch();
    const renew = () => {
        dispatch({
            type: "AUTH:RENEW-SSO"
        })
    };

    return <Modal closeTrigger={renew}>
        <ModalHeader>
            Authentication Renew
        </ModalHeader>
        <ModalContent>
            Your login session is expired. Make sure you have internet connectivity.
        </ModalContent>
        <ModalFooter>
            <Button onClick={renew}>Refresh.</Button>
        </ModalFooter>
    </Modal>;
};

const MainApp = () => {
    // Get the Feature flag from local storage.
    const isTenancyV4 = isNewTenancyEnabled();
    const lastNavRef = useRef(null);
    function focusLastNav() {
        lastNavRef?.current?.focus();
    }
    const dispatch = useDispatch();
    const isLoadingServices = useSelector(state => state.app.isLoadingServices);
    const serviceIntegrationPaths = useSelector(state => state.app.serviceIntegrationPaths);
    const { isAuthenticated, userId, renewTimeout, status } = useSelector(state => state.auth);
    const showGTCDataCollectionModal = useSelector(state => state.app.showGTCDataCollectionModal);
    const { accountStatus, selectedAccountId, workspaceStatus, agentStatus, selectedAgentId, forbidden, isChangedAgent, isTransitionFromUnselectedToSelected, permissions, selectedAccount, orgStatus, scopeStatus, selectedOrgId, orgs } = useSelector(state => state.tenancy);
    const dontShowMoveToSaasScreen = window.localStorage.getItem('occm.dont.show.move.to.saas.overlay') === "true";
    const dontShowMovedNavDialog = getItemAnon({ itemKey: `dontShowNewBlue` });
    const dontShowWhatsNew = getItemAnon({ itemKey: `dontShowWhatsNew` });
    const noActiveAgentInSaasMode = process.env.REACT_APP_IS_SAAS === "true" && agentStatus === "SUCCESS" && !selectedAgentId;
    const isFeaturesInitialized = useSelector(state => state.features.isInitialized);

    useEffect(() => {
        if (isAuthenticated) {
            if (isTenancyV4) {
                dispatch({
                    type: "TENANCYV4:FETCH-ORGS",
                    payload: { userId, pollAgents: true }
                });
            } else {
                dispatch({
                    type: "TENANCY:LOAD-ACCOUNTS"
                });
            }
        }
    }, [isAuthenticated, userId, dispatch]);

    useEffect(() => {
        if (!isLoadingServices && isAuthenticated) { //checks isAuthenticated cause feature flag manual toggles get saved with the userId
            dispatch({
                type: "APP:INITIALIZE-FEATURES"
            })
        }
    }, [isLoadingServices, dispatch, isAuthenticated, accountStatus, orgStatus]);

    useEffect(() => {
        if (isFeaturesInitialized && accountStatus === "SUCCESS" || orgStatus === "SUCCESS") { //checks accountStatus cause menu favourites get saved with accountId
            dispatch({
                type: "MENU:INITIALIZE"
            })
        }
    }, [isFeaturesInitialized, accountStatus, orgStatus]);

    if (isLoadingServices || !isAuthenticated) {
        if (status === 'PENDING') {
            return <MainLoader/>;
        } else if (status === 'UNSET') {
            return ""
        }
    }

    const showTabs = agentStatus !== "PENDING" && (selectedAccountId || selectedOrgId) && (isTenancyV4 ? !_.isEmpty(permissions) && orgStatus === "SUCCESS" && scopeStatus === "SUCCESS" : accountStatus === "SUCCESS"  && workspaceStatus === "SUCCESS");
    const noOrganizationForUser = orgStatus === "SUCCESS" && !selectedOrgId && (orgs?.length === 0);
    const showLoader = (isTenancyV4 && _.isEmpty(permissions) && !noOrganizationForUser) || accountStatus === "PENDING" || (workspaceStatus === "PENDING" && selectedAccountId) || (agentStatus === "PENDING" && selectedAccountId) || orgStatus === "PENDING" || (scopeStatus === "PENDING" && selectedOrgId) || (agentStatus === "PENDING" && selectedOrgId);
    const showLeftNav = (accountStatus === "SUCCESS" && !isTenancyV4) || (isTenancyV4 && orgStatus === "SUCCESS" && !noOrganizationForUser);
    const noAccountForUser = accountStatus === "SUCCESS" && !selectedAccountId && !forbidden;

    const whatsNewHandler = () => {
        setItemAnon({ itemKey: `dontShowWhatsNew` }, true);
    }

    return <div className={classNames(styles.base, { [styles['initializing']]: !showLeftNav })}>
        <MainHeader focusLastNav={focusLastNav}/>
        {showLeftNav && <MainNav ref={lastNavRef}/>}
        {showGTCDataCollectionModal && 
            <GTCDataCollectionPage/>
        }
        <div className={(noOrganizationForUser && isTenancyV4) ? '': styles.doodads}>
            {noAccountForUser && !isTenancyV4 && !showGTCDataCollectionModal && <div>
                <NoAccountDialog/>
            </div>}
            {noOrganizationForUser && isTenancyV4 && !showGTCDataCollectionModal && <div>
                <NoOrgDialog/>
            </div>}
            {isChangedAgent && <InactiveConnectorDialog/>}
            {process.env.REACT_APP_IS_SAAS !== "true" && !dontShowMoveToSaasScreen && process.env.REACT_APP_IS_DARK !== 'true' && selectedAccount?.isSaas && <MoveToSaas/>}
            {process.env.REACT_APP_IS_SAAS === "true" && !dontShowMovedNavDialog && selectedAccountId && <NavMovedDialog/>}
            {process.env.REACT_APP_IS_SAAS === "true" && !noOrganizationForUser && isTenancyV4 && dontShowMovedNavDialog && !dontShowWhatsNew && !showLoader && <WhatsNew whatsNewConfig={whatsNewConfig} handleCheckboxChange={whatsNewHandler}/>}
            {renewTimeout && <TimeoutModal/>}
            <SlidingManagementWidget/>
            <MonitorCurrentConnector/>
            {isTransitionFromUnselectedToSelected && <Notification type="info" style={{ width: 820, position: "fixed", bottom: 63, left: "50%", transform: "translateX(-50%)" }}>
                A Connector is now active—you can use all of the features and services within BlueXP.
            </Notification>}
        </div>

        <div className={styles.container}>
            {forbidden && <Forbidden/>}
            <MainErrorBoundary>
                <Suspense fallback={<MainLoader/>}>
                    <Switch>
                        <Route path="/playground"><Playground/></Route>
                        <Route path="/netapp-internal" render={() => <NetappInternal/>}/>
                        <Route>
                            <>
                                {showLoader && <MainLoader/>}
                                {showTabs && <Switch>
                                    <Route path="/app-redirect/:app" render={() => <DefaultRoute><RedirectManager/></DefaultRoute>}/>
                                    <Route path="/storage/on-prem/local" render={() => <DefaultRoute><StorageResources isONTAPDirect="true"/></DefaultRoute>} />
                                    <Route path={serviceIntegrationPaths} render={() => <ServiceIntegration/>}/>
                                    <Route path="/support-dashboard" render={() => <Support/>}/>
                                    <Route path="/add-working-environment" render={() => <AddNewWorkingEnvironment/>}/>
                                    <Route path="/add-working-environment-wizard" render={() => <Redirect to={{ pathname: "/add-working-environment/choose-type", hash: window.location.hash, state: history.location.state }}/>}/>
                                    {selectedAgentId && <Route path="/connector-settings/certificate-setup" render={() => <HttpsSetup/>}/>}
                                    {selectedAgentId && <Route path="/connector-settings/settings" render={() => <Settings/>}/>}
                                    <Route path="/identity-access-mgmt/" render={() => <DefaultRoute><IdentityAndAccessMgmt/></DefaultRoute>} />
                                    <Route path="/credentials" render={() => <Credentials/>}/>
                                    <Route path="/storage/:serviceKey/:provider?" render={() => <DefaultRoute><StorageResources/></DefaultRoute>}/>
                                    <Route path="/cvs/:flavour/:weId?" render={() => <CVS/>}/>
                                    <Route path="/timeline" render={() => <DefaultRoute><Timeline/></DefaultRoute>}/>
                                    <Route path="/compute" render={() => <DefaultRoute><SpotTab/></DefaultRoute>}/>
                                    <Route path="/working-environments"><DefaultRoute><GalleryTab/></DefaultRoute></Route>
                                    <Route path="/replication/setup"><DefaultRoute><ReplicationSetupWizard/></DefaultRoute></Route>
                                    <Route path="/replication"><DefaultRoute><ReplicationTab/></DefaultRoute></Route>
                                    <Route path="/licenses" render={() => <DefaultRoute><Licenses/></DefaultRoute>}/>
                                    <Route path="/alerts-and-notifications" render={() => <DefaultRoute><AlertsAndNotifications/></DefaultRoute>}/>
                                    <Route path="/alerts/:alertId" render={() => <DefaultRoute><AlertDetails /></DefaultRoute>}/>
                                    <Route path="/alerts" render={() => <DefaultRoute><Alerts /></DefaultRoute>} />
                                    <Route path={routesAvailableWithoutAnActiveConnector} render={() => <DefaultRoute/>}/>
                                    {noActiveAgentInSaasMode && permissions.addConnector && <Route path="/:path" render={() => <Redirect to={{ ...history.location, hash: "#addConnector" }}/>}/>}
                                    <Route path="/:path" render={() => <DefaultRoute/>}/>
                                    <Redirect to={{ pathname: "/working-environments", hash: window.location.hash }}/>
                                </Switch>}
                            </>
                        </Route>
                    </Switch>
                </Suspense>
            </MainErrorBoundary>
        </div>
    </div>
};

const App = React.memo(() => {
    const appState = useSelector(state => state.app.appState);
    removeMainLoader();
    return (
        <>
            {appState === 'loading' && <MainLoader/>}
            {appState === 'proxy' && <DialogLazy><ProxyDialog/></DialogLazy>}
            {appState === "setup-wizard" && <DialogLazy><SetupDialog/></DialogLazy>}
            {process.env.REACT_APP_IS_DARK === 'true' && <DialogLazy><DarkLogin/></DialogLazy>}
            <NgWrapper/>
            <Intercom/>
            <AppNotifications/>
            {appState === 'ready' && <MainApp/>}
        </>
    );
});

export default App;
