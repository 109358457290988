import _ from "lodash";
import { extractErrorMessage } from "@netapp/bxp-design-system-react"
import { setExternals } from "../../../utils/externals";
import { pauseAsyncPoller } from "utils/clients";
import { getItemAnon, setTenancyId, setOrgId, isNewTenancyEnabled } from "../../../utils/localstorage";
import { isAllowed } from "utils/permissions";
import { getTagValue, getScopeWithoutAdminAccess } from "modules/identity-access-management/utils";

const defaultState = () => ({
    orgStatus: "PENDING",
    accountStatus: "PENDING",
    workspaceStatus: "PENDING",
    agentStatus: "PENDING",
    adminOrgStatus: "PENDING",
    permissions: {},
    selectedOrg:{}
});

const derivePermissions = (selectedAccount) => {
    if (selectedAccount && selectedAccount.userRole === "Role-1") {
        return {
            addConnector: true,
            removeConnector: true,
            editAccount: true,
            allTabsAvailable: true,
            editConnectorUris: true
        }
    } else if (selectedAccount && (selectedAccount.userRole === 'Role-2')) {
        return {
            allTabsAvailable: true,
            removeConnector: true
        }
    } else if (selectedAccount && selectedAccount.userRole === "Role-4") {
        return {
            addConnector: true,
            allTabsAvailable: true,
            removeConnector: true
        }
    } else {
        return {}
    }
};

const hasAdminPermissions = (rules) => {
    return isAllowed(rules, "organization", "modify") || isAllowed(rules, "project", "modify") || isAllowed(rules, "folder", "modify")
}

const hasEditAccountPermissions = (scope) => {
    if (hasAdminPermissions(scope.rules)) {
        return true
    } else {
        for (const childScope of scope.childScopes) {
            if (hasEditAccountPermissions(childScope)) {
                return true;
            }
        }
        return false
    }
}

const deriveV4Permissions = (rules = [], isRole4, scopes) => {
    const editAccount = hasEditAccountPermissions(scopes);
    return {
        addConnector: isAllowed(rules, "agent", "create"),
        removeConnector: isRole4 || isAllowed(rules, "agent", "delete"),
        editAccount,
        allTabsAvailable: isRole4 || hasAdminPermissions(rules),
        editConnectorUris: isAllowed(rules, "agent", "read"),
    }
};

const updateAccountRole = (accounts, orgAdmins) => {
    for (const account of accounts) {
        
        const org = _.find(orgAdmins, (org) => {
            const tag = _.find(org.tags, { "internal:bxp:legacyId": account.accountPublicId })
            return !_.isUndefined(tag);
        })
        
        if (org) {
            account.userRole = "Role-1";
        } else {
            account.userRole = "";
        }
    }
}

const getAccountDetails = (selectedOrg) => {
    const accountDetails = {
        accountPublicId: selectedOrg?.legacyId,
        accountName: selectedOrg?.name
    };
    if (selectedOrg?.tags) {
        for (const tag of selectedOrg?.tags) {
            if (tag["internal:bxp:saas"]) {
                accountDetails.isSaas = tag["internal:bxp:saas"]
            } else if (tag["internal:bxp:gov"]) {
                accountDetails.isGov = tag["internal:bxp:gov"]
            } else if (tag["internal:bxp:privatePreview"]) {
                accountDetails.isPrivatePreviewEnabled = tag["internal:bxp:privatePreview"]
            } else if (tag["internal:bxp:thirdPartyServices"]) {
                accountDetails.is3rdPartyServicesEnabled = tag["internal:bxp:thirdPartyServices"]
            } else if (tag["internal:bxp:serial"]) {
                accountDetails.accountSerial = tag["internal:bxp:serial"]
            }
        }
    }
    return accountDetails;
}

const demoAccounts = _.chain(process.env.REACT_APP_DEMO_ACCOUNTS).split(";").map(_.trim).reject(_.isEmpty).value();

const trimClients = (agentId) => {
    return agentId?.substring(0, agentId.length - "clients".length);
}

const updateAccount = (newAccount, state) => {
    const { selectedAccountId } = state;
    const accounts = state.accounts.map(account => {
        if (account.accountPublicId === selectedAccountId) {
            return newAccount;
        } else {
            return account;
        }
    });

    return { accounts, selectedAccount: newAccount }
}

const updateOrganization = (newOrganization, state) => {
    const { selectedOrganizationId } = state;
    const organizations = state.organizations.map(organization => {
        if (organization.id === selectedOrganizationId) {
            return newOrganization;
        } else {
            return organization;
        }
    });

    return { organizations, selectedOrganization: newOrganization }
}

export default (state = defaultState(), { type, payload, request }) => {
    const isTenancyV4 = isNewTenancyEnabled();
    switch (type) {
        case "APP:BOOT-SUCCESS": {
            if (process.env.REACT_APP_IS_SAAS === "false") {
                const { accountId, clientId } = payload;
                return { ...state, localAccountId: accountId, localConnectorId: `${clientId}clients` }
            } else {
                return state;
            }
        }
        // TODO to be cleaned up
        case "TENANCY:LOAD-ACCOUNTS-SUCCESS": {
            const { accounts, selectedAccountId, forbidden } = payload;
            const selectedAccount = _.find(accounts, { accountPublicId: selectedAccountId });

            const isDemo = _.includes(demoAccounts, selectedAccountId) || getItemAnon({ itemKey: `feature.demo-mode` });

            if (isDemo) {
                setExternals({ isDemo });
                pauseAsyncPoller();
            }

            setTenancyId({ tenancyId: selectedAccountId });
            return {
                ...state,
                accountStatus: "SUCCESS",
                orgStatus: "SUCCESS",
                isDemo,
                accounts: _.sortBy(accounts, [account => account.accountName.toLowerCase()]),
                selectedAccountId,
                selectedAccount,
                forbidden,
                permissions: derivePermissions(selectedAccount)
            }
        }
        case "TENANCY:SET-ACCOUNTS": {
            const { selectedAccount , selectedAccountId, forbidden, accounts} = payload;

            const isDemo = _.includes(demoAccounts, selectedAccountId) || getItemAnon({ itemKey: `feature.demo-mode` });

            setTenancyId({ tenancyId: selectedAccountId });
            const accs = _.isUndefined(accounts) ? state.accounts : accounts;
            const forbiddenValue = _.isUndefined(forbidden) ? state.forbidden : forbidden;
            return {
                ...state,
                accounts: accs,
                accountStatus: "SUCCESS",
                isDemo,
                selectedAccountId,
                selectedAccount,
                forbidden: forbiddenValue
            }
        }
        // end
        case "TENANCYV4:FETCH-USER-SUCCESS": {
            return {
                ...state,
                user: payload.user,
            }
        }
        case "TENANCYV4:SET-PERMISSIONS": {
            const { scopeId } = payload;
            const scopes = [state.selectedOrg, ...state.scopes]
            const rules = _.flatten(scopes.map(scope => scope.rules))
            const scope = _.find(scopes, { id: scopeId });
            let isRole4 = false;
            if (scope?.roles) {
                for (const role of scope.roles) {
                    const selectedRole = _.find(state.roles, { id: role.id });
                    // TODO to be removed later
                    isRole4 = getTagValue(selectedRole.tags, "internal:bxp:legacyId") === "Role-4";
                    if (isRole4) {
                        break;
                    }
                }
            }
            
            return {
                ...state,
                permissions: scope ? deriveV4Permissions(scope ? scope.rules : {}, isRole4, state.selectedOrg) : state.permissions,
                rules: rules
            }
        }
        case "TENANCYV4:SET-ROLES-PERMISSIONS": {
            const { permissions } = payload;
            let roles = state.roles;
            const roleRuleMap = {};
            for (const permission of permissions) {
                for(const tag of permission.tags) {
                    if (tag["internal:bxp:roleId"]) {
                        if (!roleRuleMap[tag["internal:bxp:roleId"]]) {
                            roleRuleMap[tag["internal:bxp:roleId"]] = []
                        }
                        roleRuleMap[tag["internal:bxp:roleId"]].push(permission.rule)
                    }
                }
            }
            roles = roles.map(role => {
                role.rules = roleRuleMap[role.id];
                return role;
            });
            return {
                ...state,
                roles
            }
        }
        case "TENANCYV4:FETCH-USER-FAILED": {
            return {
                ...state,
                userError: extractErrorMessage(payload),
            }
        }
        case "TENANCYV4:FETCH-ROLES-SUCCESS": {
            return {
                ...state,
                roles: payload.roles                
            }
        }
        case "TENANCYV4:FETCH-ROLES-FAILED": {
            return {
                ...state,
                rolesError: extractErrorMessage(payload),
            }
        }
        case "TENANCYV4:FETCH-ORGS-SUCCESS": {
            const { orgs, selectedOrgId, forbidden } = payload;
            const selectedOrg = _.find(orgs, { id: selectedOrgId });
            const accounts = [];
            // TODO to be removed on scope and organization integration
            for (const org of orgs) {
                accounts.push(getAccountDetails(org))
            }

            if(state.selectedOrg.rules) {
                selectedOrg.rules = state.selectedOrg.rules
            }

            const isDemo = _.includes(demoAccounts, selectedOrgId) || getItemAnon({ itemKey: `feature.demo-mode` });

            if (isDemo) {
                setExternals({ isDemo });
                pauseAsyncPoller();
            }

            setOrgId({ orgId: selectedOrgId });
            setTenancyId({ tenancyId: selectedOrg?.legacyId });
            return {
                ...state,
                orgStatus: "SUCCESS",
                accountStatus: "SUCCESS",
                accounts,
                isDemo,
                orgs: _.sortBy(orgs, [org => org.name.toLowerCase()]),
                selectedOrgId,
                selectedOrg,
                selectedAccount: getAccountDetails(selectedOrg),
                selectedAccountId: selectedOrg?.legacyId,
                forbidden
            }
        }
        case "TENANCYV4:FETCH-ORGS-FAILED": {
            return {
                ...state,
                orgStatus: "FAILED",
                accountStatus: "FAILED",
            }
        }
        case "TENANCYV4:FETCH-ORGS-PENDING": {
            return {
                ...state,
                orgStatus: "PENDING",
            }
        }
        case "TENANCYV4:LOAD-ADMIN-ORGS-SUCCESS": {
            const { adminOrgs } = payload;
            const accounts = state.accounts;
            updateAccountRole(accounts, adminOrgs)
            return {
                ...state,
                adminOrgStatus: "SUCCESS",
                adminOrgs,
                accounts
            }
        }
        case "TENANCYV4:LOAD-ADMIN-ORGS-FAILED": {
            return {
                ...state,
                adminOrgStatus: "FAILED",
            }
        }
        case "TENANCY:NO-AGENTS": {
            return {
                ...state,
                agentStatus: "NONE"
            }
        }
        case "TENANCY/LOAD-FAILED": {
            return {
                ...state,
                accountStatus: "FAILED"
            }
        }

        // TODO to be cleaned up
        case "TENANCY:LOAD-WORKSPACES-SUCCESS": {
            const { workspaces, selectedWorkspaceId } = payload;
            const selectedWorkspace = _.find(workspaces, { workspacePublicId: selectedWorkspaceId });
            return {
                ...state,
                workspaceStatus: "SUCCESS",
                workspaces: _.sortBy(workspaces, [workspace => workspace.workspaceName.toLowerCase()]),
                selectedWorkspaceId,
                selectedWorkspace
            }
        }
        case "TENANCY:LOAD-WORKSPACES-FAILED": {
            return {
                ...state,
                workspaceStatus: "FAILED",
                workspaceErrorReason: extractErrorMessage(payload)
            }
        }
        case "TENANCY:SET-WORKSPACE": {
            let { workspaceId, workspace } = payload;
            const selectedWorkspace = _.isUndefined(workspace) ? _.find(state.workspaces, { workspacePublicId: workspaceId }) : workspace;
            return {
                ...state,
                workspaceStatus: "SUCCESS",
                selectedWorkspace,
                selectedWorkspaceId: workspaceId
            }
        }
        // end
        case "TENANCYV4:FETCH-SCOPES-SUCCESS": {
            const { scopes, selectedScopeId } = payload;
            const selectedScope = _.find(scopes, { id: selectedScopeId });
            return {
                ...state,
                scopeStatus: "SUCCESS",
                workspaceStatus: "SUCCESS",
                scopes,
                selectedScopeId,
                selectedScope,
                selectedWorkspace: selectedScope ? {
                    workspacePublicId: selectedScope.legacyId,
                    workspaceName: selectedScope.name,
                    associatedAgents: selectedScope.associatedAgents,
                } : undefined,
                selectedWorkspaceId: selectedScope ? selectedScope.legacyId : undefined,
                isLoading: false,
            }
        }
        case "TENANCYV4:FETCH-SCOPES-FAILED": {
            return {
                ...state,
                scopeStatus: "FAILED",
                workspaceStatus: "FAILED",
                scopeErrorReason: extractErrorMessage(payload),
                isLoading: false,
            }
        }
        case "TENANCYV4:FETCH-SCOPES-LOADING": {
            return {
                ...state,
                isLoading: true,
            }
        }
        case "TENANCYV4:SET-SCOPE": {
            const { scopeId } = payload;
            const selectedScope = _.find(state.scopes, { id: scopeId });
            // connectorChanges: Remove this comment once the agent association is approved. 
            // const selectedAgent = (selectedScope?.associatedAgents?.length > 0) ? selectedScope?.associatedAgents[0] : undefined;
            // const selectedAgentId = (selectedScope?.associatedAgents?.length > 0) ? selectedScope?.associatedAgents[0].agentId : undefined;

            return {
                ...state,
                selectedScope,
                selectedScopeId: scopeId,
                // selectedAgent,
                // selectedAgentId,
                selectedWorkspace: {
                    workspacePublicId: selectedScope.legacyId,
                    workspaceName: selectedScope.name,
                    associatedAgents: selectedScope.associatedAgents,
                },
                selectedWorkspaceId: selectedScope.legacyId,
            }
        }
        case "TENANCY:SET-AGENT-USER-SUCCESS": {
            const { agentUserId, agentId } = payload;
            let { userAgentMap } = state;
            if (_.isUndefined(userAgentMap)) {
                userAgentMap = {}
            }
            if (agentId && _.isUndefined(userAgentMap[agentId])) {
                userAgentMap[agentId] = agentUserId
            }
            return {
                ...state,
                agentStatus: "SUCCESS",
                userAgentMap,
                selectedAgentUserId: agentUserId,
            }
        }
        case "TENANCY:SET-AGENT-USER-FAILED": {
            return {
                ...state,
                agentStatus: "FAILED",
            }
        }
        case "TENANCY:LOAD-AGENTS-SUCCESS": {
            const { agents, selectedAgentId, isChangedAgent, isTransitionFromUnselectedToSelected } = payload;
            // connectorChanges: Remove this comment once the agent association is approved. 
            // const selectedAgent = _.find(isTenancyV4 ? state.selectedScope?.associatedAgents : agents, { agentId: selectedAgentId });
            const selectedAgent = _.find(agents, { agentId: selectedAgentId });

            return {
                ...state,
                agentStatus: isTenancyV4 && state.agentStatus !== "SUCCESS" ? "PENDING" : "SUCCESS",
                agents,
                selectedAgentId,
                selectedAgentIdNoClients: trimClients(selectedAgentId),
                selectedAgent,
                isChangedAgent: state.isChangedAgent || isChangedAgent, //Won't set it to false if it is already true
                isTransitionFromUnselectedToSelected: state.isTransitionFromUnselectedToSelected || isTransitionFromUnselectedToSelected
            }
        }
        case "TENANCYV4:LOAD-AGENTS-SUCCESS": {
            const { scopes, selectedScopeId } = payload;
            const selectedScope = _.find(scopes, { id: selectedScopeId });
            return {
                ...state,
                scopes,
                selectedScopeId,
                selectedScope,
                selectedWorkspace: {
                    workspacePublicId: selectedScope.legacyId,
                    workspaceName: selectedScope.name,
                    associatedAgents: selectedScope.associatedAgents,
                },
                selectedWorkspaceId: selectedScope.legacyId,
            }
        }
        case "TENANCY:DISCARD-CHANGE-AGENT-MESSAGE": {
            return { ...state, isChangedAgent: false, isTransitionFromUnselectedToSelected: false }
        }
        case "TENANCY:LOAD-AGENTS-FAILED": {
            console.log("Failed loading agents");
            console.log(payload);
            return {
                ...state,
                agentStatus: "FAILED",
                agentErrorReason: extractErrorMessage(payload)
            }
        }
        case "TENANCY:SET-AGENT": {
            const { agentId} = payload;
            // connectorChanges: Remove this comment once the agent association is approved. 
            // const selectedAgent = _.find(isTenancyV4 ? state.tenancy.selectedScope?.associatedAgents : state.agents, { agentId: agentId });
            const selectedAgent = _.find(state.agents, { agentId: agentId });

            return {
                ...state,
                agentStatus: isTenancyV4 ? "PENDING" : "SUCCESS",
                selectedAgent,
                selectedAgentId: agentId,
                selectedAgentIdNoClients: trimClients(agentId)
            }
        }
        // TODO To be cleaned up
        case "TENANCY:CREATE-ACCOUNT-PENDING": {
            return {
                ...state,
                creatingAccount: true,
                creatingAccountFailed: false
            }
        }
        case "TENANCY:CREATE-ACCOUNT-SUCCESS": {
            return {
                ...state,
                creatingAccount: false,
                creatingAccountFailed: false,
                creatingAccountSuccess: true
            }
        }
        case "TENANCY:CREATE-ACCOUNT-FAILED": {
            return {
                ...state,
                creatingAccount: false,
                creatingAccountFailed: true
            }
        }
        // end

        case "CONNECTORS:REMOVE-CONNECTOR-SUCCESS": {
            const { agentId } = payload;
            const agents = _.reject(state.agents, { agentId });
            return {
                ...state,
                agents
            }
        }
        case "CONNECTORS:UPDATE-URIS-SUCCESS": {
            const { agentId, redirectUris } = payload;
            const newPrimaryCallbackUri = _.find(redirectUris, "isDefault")?.value;

            const agents = _.map(state.agents, agent => {
                return agent.agentId === agentId ? { ...agent, primaryCallbackUri: newPrimaryCallbackUri || agent.automaticCallbackUris?.[0], manualOverrideUris: redirectUris } : agent;
            });

            return {
                ...state,
                agents
            }
        }
        // TODO to be removed
        case "TENANCY:ADD-WORKSPACE-SUCCESS": {
            const { workspaceName, workspacePublicId } = payload;
            const workspaces = _.sortBy([{ workspaceName, workspacePublicId }].concat(state.workspaces), [workspace => workspace.workspaceName.toLowerCase()]);

            return { ...state, workspaces }
        }
        case "TENANCY:DELETE-WORKSPACE-SUCCESS": {
            const { workspaceId } = payload;
            const workspaces = _.reject(state.workspaces, { workspacePublicId: workspaceId });

            if (workspaceId === state.selectedWorkspaceId) {
                return { ...state, workspaces, selectedWorkspace: workspaces[0], selectedWorkspaceId: workspaces[0].id }
            } else {
                return { ...state, workspaces }
            }
        }
        case "TENANCY:RENAME-WORKSPACE-SUCCESS": {
            const { newName, workspaceId } = payload;

            let newWorkspace = null;
            const workspaces = _.map(state.workspaces, workspace => {
                if (workspace.workspacePublicId === workspaceId) {
                    newWorkspace = { ...workspace, workspaceName: newName };
                    return newWorkspace;
                } else {
                    return workspace;
                }
            });

            if (workspaceId === state.selectedWorkspaceId && newWorkspace) {
                return { ...state, workspaces, selectedWorkspace: newWorkspace }
            } else {
                return { ...state, workspaces }
            }
        }
        case "TENANCY-RENAME-ACCOUNT-SUCCESS": {
            const { newName } = payload;
            const { selectedAccountId } = state;

            let newAccount = state.selectedAccount;
            const accounts = _.sortBy(state.accounts.map(account => {
                if (account.accountPublicId === selectedAccountId) {
                    newAccount = { ...account, accountName: newName };
                    return newAccount;
                } else {
                    return account;
                }
            }), [account => account.accountName.toLowerCase()]);

            return { ...state, accounts, selectedAccount: newAccount }
        }
        case "TENANCY-ACCOUNT-SAAS-ENABLED-TOGGLE-SUCCESS": {
            const { value } = payload;

            let newAccount = { ...state.selectedAccount, isSaas: value };
            return { ...state, ...updateAccount(newAccount, state) }
        }
        case "ACCOUNTS-MANAGEMENT:TOGGLE-PRIVATE-PREVIEW-SUCCESS": {
            const { payload: { value } } = request;

            let newAccount = { ...state.selectedAccount, isPrivatePreviewEnabled: value };
            return { ...state, ...updateAccount(newAccount, state) }
        }
        case "ACCOUNTS-MANAGEMENT:TOGGLE-ALLOW-THIRD-PARTY-SERVICES-SUCCESS": {
            const { payload: { value } } = request;

            let newAccount = { ...state.selectedAccount, is3rdPartyServicesEnabled: value };
            return { ...state, ...updateAccount(newAccount, state) }
        }
        case "TENANCYV4:UPDATE-ORGANIZATION": {
            const { name, id } = payload;
            const { orgs, selectedOrg } = state;
            const updatedSelectedOrg = {...selectedOrg }
            updatedSelectedOrg.name = name;
            const updatedOrg = orgs.map((org) => {
                if(org.id === id ) {
                    org.name = name;
                }
                return org;
            })
            return {
                ...state,
                selectedOrg: updatedSelectedOrg,
                orgs: updatedOrg
            }
        }
        case "TENANCYV4:UPDATE-SELECTED-ORG": {
            const { selectedOrg } = payload;
            return {
                ...state,
                selectedOrg,
            }
        }

        default: {
            return state;
        }
    }
    //end
};
